import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {
	private readonly encryptionKey = 'aPdP[^bD£yYuXY#Op4q,)j1K0e#4E{v';

	constructor() {}

	encryptData(data: string): string {
		return btoa(this.xorOperation(data, this.encryptionKey));
	}
	
	decryptData(encryptedData: string): string {
		return this.xorOperation(atob(encryptedData), this.encryptionKey);
	}

	private xorOperation(text: string, key: string): string {
		let result = '';
		for (let i = 0; i < text.length; i++) {
		result += String.fromCharCode(text.charCodeAt(i) ^ key.charCodeAt(i % key.length));
		}
		return result;
	}

	set(obj: any, key: string) {
		let json = JSON.stringify(obj);
		let encryptData = this.encryptData(json);
		localStorage.setItem(key, encryptData);	
	}

	get(key: string): any {
		let encrypt = localStorage.getItem(key);
		if (encrypt) {
		try {
			let decryptData = this.decryptData(encrypt);
			return JSON.parse(decryptData);
		} catch (error) {
			console.error('Error decrypting or parsing data:', error);
			return null;
		}
		}
		return null;
	}
}