import { base } from 'app/types/base.type';

export class Scope {
    dashboard? : boolean = false
    user? : boolean = false
    chat? : boolean = false
    lesson? : boolean = false
    lesson_factory? : boolean = false
    frase_picture? :boolean = false
    dictionary? : boolean = false
    watchquiz? : boolean = false
    watchquiz_factory? : boolean = false
    category? : boolean = false
    category_factory? : boolean = false
    role? : boolean = false
    feedback? : boolean = false
    roleplaying? : boolean = false
    roleplaying_factory? : boolean = false
    picture_description? : boolean = false
    picture_description_factory? : boolean = false
    school? : boolean = false
    classroom? : boolean = false
    classroom_factory? : boolean = false
    schedule? : boolean = false
    schedule_factory? : boolean = false
    collaborator? : boolean = false
    collaborator_factory? : boolean = false
    is_admin? : boolean = false
    is_co_admin? : boolean = false
    is_teacher? : boolean = false
    is_school_student? : boolean = false
    is_student? : boolean = false
}